import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import LoginAuthGuard from './components/LoginAuthGuard';
import TimeTracking from './pages/TimeTracking';
import OtherIncome from './pages/OtherIncome';
import ShopifySyncingLogin from './pages/ShopifySyncingLogin';
import TogglSyncingLogin from './pages/TogglSyncingLogin';
import Landing from './pages/Landing';
import AuthGuard from './components/AuthGuard';
import FBSyncingShopifyAppLogin from './pages/FBSyncingShopifyAppLogin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LandingPage from './pages/LandingPage';
import InvoiceInStyleLogin from './pages/InvoiceInStyleLogin';
import InvoiceInStyle from './pages/InvoiceInStyle';
import InvoiceTemplates from './pages/InvoiceTemplates';
import SharableLinkPage from './pages/SharableLinkPage';
import EcommerceShop from './pages/Products';
import LinkDocDetailPage from './pages/LinkDocDetailPage';
import TermsConditions from './pages/TermsConditions';

// ----------------------------------------------------------------------

export const FBAppLoginURL = {
  fb_syncing_shopify_app: '/fbsyncing_shopify_login',
  shopify_syncing: '/shopify_syncing_login',
  toggl_sycing: '/toggl_syncing_login',
  invoice_in_style: '/invoice_in_style_login',
};

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'timetracking',
          element: (
            <AuthGuard>
              <TimeTracking />
            </AuthGuard>
          ),
        },
        {
          path: 'google-calendar',
          element: (
            <AuthGuard>
              <TimeTracking />
            </AuthGuard>
          ),
        },
        {
          path: 'otherincome',
          element: (
            <AuthGuard>
              <OtherIncome />
            </AuthGuard>
          ),
        },
        {
          path: 'invoiceinstyle',
          element: (
            <AuthGuard>
              <InvoiceInStyle />
            </AuthGuard>
          ),
        },
        { path: 'invoiceinstyle/:linkID', element: <LinkDocDetailPage /> },
        {
          path: 'invoice-templates',
          element: (
            <AuthGuard>
              <InvoiceTemplates />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: '/', element: <Landing /> },
    { path: '/product', element: <EcommerceShop /> },
    { path: '/link/:linkID', element: <SharableLinkPage /> },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: FBAppLoginURL.fb_syncing_shopify_app,
          element: (
            <LoginAuthGuard>
              <FBSyncingShopifyAppLogin />
            </LoginAuthGuard>
          ),
        },
        {
          path: FBAppLoginURL.shopify_syncing,
          element: (
            <LoginAuthGuard>
              <ShopifySyncingLogin />
            </LoginAuthGuard>
          ),
        },
        {
          path: FBAppLoginURL.toggl_sycing,
          element: (
            <LoginAuthGuard>
              <TogglSyncingLogin />
            </LoginAuthGuard>
          ),
        },
        {
          path: FBAppLoginURL.invoice_in_style,
          element: (
            <LoginAuthGuard>
              <InvoiceInStyleLogin />
            </LoginAuthGuard>
          ),
        },
        { path: '404', element: <NotFound /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-conditions', element: <TermsConditions /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '/landing', element: <LandingPage /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

import PropTypes from 'prop-types';
// material
import {
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { filter } from 'lodash';
import moment from 'moment';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';
import TemplateCard from './TemplateCard';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';

// ----------------------------------------------------------------------

InvoiceTable.propTypes = {
  invoiceList: PropTypes.array.isRequired,
  onRecordClick: PropTypes.func,
  handleRefresh: PropTypes.func,
};

export default function InvoiceTable({ invoiceList, onRecordClick, handleRefresh, isLoading }) {
  // table state
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createDate');
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);

  // invoice related state
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const TABLE_HEAD = [
    { id: 'clientName', label: 'FreshBooks Client / Invoice #', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'issueDate', label: 'Issued Date / Due', alignRight: false },
    { id: 'fbStatus', label: 'Amount / FB Status', alignRight: true },
  ];

  // table function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  function applySortFilter(array, comparator, query) {
    if (!array || array.length === 0) return [];
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (record) =>
          record.clientName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          record.invoiceNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          record.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'createDate') {
      if (moment(b[orderBy]).isBefore(a[orderBy])) return -1;
      if (moment(b[orderBy]).isAfter(a[orderBy])) return 1;
      if (moment(b[orderBy]).isSame(a[orderBy])) return 0;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const filteredRecords = applySortFilter(invoiceList, getComparator(order, orderBy), filterName);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoiceList.length) : 0;
  const isRecordNotFound = filterName !== '' && filteredRecords.length === 0;
  const handleRowClick = (record) => {
    console.log('Selected record', record);
    setSelectedInvoice(record);
    onRecordClick(record);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Invoice related table function
  const getFBStatusChipColor = (statusName) => {
    switch (statusName) {
      case 'created':
      case 'draft':
        return 'default';
      case 'sent':
      case 'viewed':
      case 'retry':
      case 'partial':
        return 'warning';
      case 'success':
      case 'autopaid':
      case 'paid':
        return 'success';
      case 'failed':
      case 'overdue':
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <Card>
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        filterPlaceholder={'Search Client / Description / invoice #...'}
        onRefresh={handleRefresh}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredRecords.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {!isLoading &&
                filteredRecords.length !== 0 &&
                filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => {
                  const {
                    id,
                    invoiceNumber,
                    clientName,
                    description,
                    issueDate,
                    dueDate,
                    totalAmount,
                    fbStatus,
                    fbPaymentStatus,
                    currency,
                  } = record;
                  const currencyFormatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency,
                  });

                  const displayTotalAmount = currencyFormatter.format(totalAmount);
                  const isItemSelected = selected.indexOf(record) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      onClick={() => handleRowClick(record)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                        <Typography variant="subtitle2" noWrap>
                          {clientName || '<No Client>'}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {invoiceNumber || '<Not Available>'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ maxWidth: 500 }}>
                        {description || '<Not Available>'}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                        <Typography variant="subtitle2" noWrap>
                          {issueDate || '<Not Available>'}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {moment(dueDate).fromNow() || '<Not Available>'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {displayTotalAmount || '<Not Available>'}
                        </Typography>
                        <Label variant="ghost" color={getFBStatusChipColor(fbStatus)}>
                          {fbStatus}
                        </Label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && !isLoading && (
                <TableRow style={{ height: 56 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isRecordNotFound && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={filteredRecords.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

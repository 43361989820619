import { useCallback, useEffect } from 'react';
import {
  collection,
  query as dbQuery,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  limit,
  db,
  setDoc,
  updateDoc,
  increment,
} from 'firebase/firestore';
import html2pdf from 'html2pdf.js/dist/html2pdf';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { getTotalAmount } from '../sections/@dashboard/invoiceTemp/invoiceUtil';
import { envVariable } from '../utils/constant';
import useFirebase from './useFirebase';

const useTemplates = () => {
  const { firestoreDB } = useFirebase();

  // get user object from db via freshbooks id
  const getTemplates = useCallback(async () => {
    try {
      const q = dbQuery(collection(firestoreDB, 'template'));
      const querySnapshot = await getDocs(q);
      const templates = [];
      querySnapshot.forEach((doc) => {
        templates.push({ id: doc.id, ...doc.data() });
      });
      console.log('getTemplates', templates);
      return templates;
    } catch (e) {
      console.error('Error fetch templates document: ', e);
    }
  }, []);

  const downloadPDF = (fileName, templateRef) => {
    console.log('Ref size:', templateRef.current.clientHeight, templateRef.current.clientWidth);

    const opt = {
      filename: fileName,
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf().set(opt).from(templateRef.current).save();
  };

  const getTemplateByID = useCallback(async (tempID) => {
    try {
      const docRef = doc(firestoreDB, 'template', tempID);
      const tempSnapshot = await getDoc(docRef);
      const template = { id: tempID, ...tempSnapshot.data() };
      console.log('getTemplateByID', template);
      return template;
    } catch (e) {
      console.error('Error fetch template by ID document: '.tempID, e);
    }
  }, []);

  const viewPlusOne = useCallback(async (tempID) => {
    await updateDoc(doc(firestoreDB, 'template', tempID), {
      views: increment(1),
    });
  }, []);

  const downloadPlusOne = useCallback(async (tempID) => {
    await updateDoc(doc(firestoreDB, 'template', tempID), {
      downloads: increment(1),
    });
  }, []);

  const sendPlusOne = useCallback(async (tempID) => {
    await updateDoc(doc(firestoreDB, 'template', tempID), {
      send: increment(1),
    });
  }, []);

  const sendInvoiceEmail = useCallback(async (invoice, toEmails, sharableLink, notes) => {
    if (!invoice) return;
    // const user = await getUser();
    const recipients = [];
    const variables = [];
    const defaultClientName = invoice.clientContacts[0].fname;
    toEmails.forEach((email) => {
      recipients.push({ email, name: '' });
      variables.push({
        email,
        substitutions: [
          {
            var: 'due_date',
            value: moment(invoice.dueDate).format('MMM Mo, YYYY'),
          },
          {
            var: 'company_name',
            value: invoice.ownerInfo.name,
          },
          {
            var: 'client_name',
            value: _.find(invoice.clientContacts, (contact) => contact.email === email)?.fname || defaultClientName,
          },
          {
            var: 'total_amount',
            value: getTotalAmount(invoice),
          },
          {
            var: 'company_email',
            value: invoice.ownerContact.email,
          },
          {
            var: 'invoice_number',
            value: invoice.invoiceNumber,
          },
          {
            var: 'additional_notes',
            value: notes || 'Thank you!',
          },
          {
            var: 'invoice_sharable_url',
            value: sharableLink,
          },
          {
            var: 'company_logo_url',
            value: invoice.presentation.logo,
          },
        ],
      });
    });

    try {
      await axios.post(`${envVariable.onetracking_backend_url}/email/send`, {
        subject: `Invoice (${invoice.invoiceNumber}) from ${invoice.ownerInfo.name}`,
        template_id: envVariable.invoice_email_template_id,
        sendByName: `${invoice.ownerInfo.name} via Gelatech`,
        replyToName: invoice.ownerContact.fname,
        replyToEmail: invoice.ownerContact.email,
        recipients,
        variables,
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const sendSupportEmail = useCallback(async (userName, userEmail, feedback, sessionID, appName) => {
    const recipients = [];
    const cc = [{ email: envVariable.gelatech_support_email, name: 'Victor at Gelatech' }];
    const variables = [];
    const toEmails = [userEmail];
    toEmails.forEach((email) => {
      recipients.push({ email, name: userName });
      variables.push({
        email,
        substitutions: [
          {
            var: 'name',
            value: userName,
          },
          {
            var: 'session_id',
            value: sessionID,
          },
          {
            var: 'feedback',
            value: feedback,
          },
        ],
      });
    });

    try {
      await axios.post(`${envVariable.onetracking_backend_url}/email/send`, {
        subject: `Re: Your feedback for ${appName}`,
        template_id: envVariable.support_email_template_id,
        sendByName: 'Gelatech Support',
        replyToName: 'Gelatech Support',
        replyToEmail: envVariable.gelatech_support_email,
        recipients,
        variables,
        cc,
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return {
    getTemplates,
    getTemplateByID,
    viewPlusOne,
    downloadPlusOne,
    sendPlusOne,
    downloadPDF,
    sendInvoiceEmail,
    sendSupportEmail,
  };
};

export default useTemplates;

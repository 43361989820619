import PropTypes from 'prop-types';
// material
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Checkbox,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useState } from 'react';
import { filter, each } from 'lodash';
import moment from 'moment';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';
import useLink from '../../../hooks/useLink';
import TemplateCard from './TemplateCard';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';
import DocTableToolbar from './DocTableToolbar';
import { getLinkDocStatusLabel } from '../invoiceTemp/invoiceUtil';

// ----------------------------------------------------------------------

DocTable.propTypes = {
  docList: PropTypes.array.isRequired,
  tempList: PropTypes.array,
  onRecordClick: PropTypes.func,
  handleRefresh: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default function DocTable({ docList, tempList, onRecordClick, handleRefresh, isLoading }) {
  // table state
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updateDate');
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { deleteLinkDocs } = useLink();

  // Doc function
  const getTemplateChip = (templateID) => {
    let tempName = '';
    each(tempList, (temp) => {
      if (temp.id === templateID) tempName = temp.name;
    });
    return (
      <Label variant="filled" color="default">
        {tempName}
      </Label>
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredRecords);
      return;
    }
    setSelected([]);
  };

  const getSentToName = (templateID, originDoc) => {
    // console.log(templateID, originDoc);
    switch (templateID) {
      case 'purchase_order_temp':
        return originDoc.additionalInfo?.shipperInfo?.name;
      case 'packing_slip_temp':
        return originDoc.additionalInfo?.shipperInfo?.name;
      default:
        return originDoc.clientName;
    }
  };

  // invoice related state
  const [selectedDoc, setSelectedDoc] = useState(null);
  const TABLE_HEAD = [
    { id: 'sendTo', label: 'Client or Vendor', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'updateDate', label: 'Last Updated', alignRight: false },
    { id: 'docStatus', label: 'Amount / Status', alignRight: true },
  ];

  // table function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // TODO: need updated based on the search logic
  function applySortFilter(array, comparator, query) {
    if (!array || array.length === 0) return [];
    // console.log('applysortfilter', array);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (record) =>
          getSentToName(record.template_id, record.origin_doc).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          (record.origin_doc.description &&
            record.origin_doc.description.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'updateDate') {
      const dateA = moment.unix(a.update_date);
      const dateB = moment.unix(b.update_date);
      if (dateB.isBefore(dateA)) return -1;
      if (dateB.isAfter(dateA)) return 1;
      if (dateB.isBefore(dateA)) return 0;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const filteredRecords = applySortFilter(docList, getComparator(order, orderBy), filterName);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - docList.length) : 0;
  const isRecordNotFound = filterName !== '' && filteredRecords.length === 0;
  const handleRowClick = (record) => {
    // console.log('Selected record', record);
    setSelectedDoc(record);
    onRecordClick(record);
  };
  const handleRowSelect = (event, record) => {
    event.stopPropagation();
    if (selected.includes(record)) {
      setSelected(selected.filter((r) => r !== record));
    } else {
      setSelected([...selected, record]);
    }
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    setDeleteDialogOpen(false);
    console.log('delete selected', selected);
    deleteLinkDocs(selected.map((doc) => doc.id)).then(() => {
      setSelected([]);
      handleRefresh();
    });
  };

  return (
    <>
    <Card>
      <DocTableToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        filterPlaceholder={'Search Sent To / Description'}
        onRefresh={handleRefresh}
        onDeleteSelected={()=>setDeleteDialogOpen(true)}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredRecords.length}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {!isLoading &&
                filteredRecords.length !== 0 &&
                filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => {
                  const {
                    id,
                    invoice_id: invoiceID,
                    create_date: createdDate,
                    update_date: updateDate,
                    additional_info: additionalInfo,
                    origin_doc: originDoc,
                    template_id: templateID,
                    doc_status: docStatus,
                  } = record;

                  const { currency, totalAmount, clientName, description } = originDoc;

                  const currencyFormatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency,
                  });

                  const displayTotalAmount = currencyFormatter.format(totalAmount);
                  const isItemSelected = selected.indexOf(record) !== -1;
                  const sendTo = clientName;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      onClick={() => handleRowClick(record)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={(event) => handleRowSelect(event, record)} />
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                        <Typography variant="subtitle2" noWrap>
                          {getSentToName(templateID, originDoc) || '<Not Available>'}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {getTemplateChip(templateID) || '<Not Available>'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ maxWidth: 500 }}>
                        {description || '<Not Available>'}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                        <Typography variant="subtitle2" noWrap>
                          {moment.unix(updateDate).format('YYYY-MM-DD') || '<Not Available>'}
                        </Typography>
                        <Typography variant="caption" noWrap>
                          {moment.unix(updateDate).fromNow(true) || '<Not Available>'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {displayTotalAmount || '<Not Available>'}
                        </Typography>
                        {getLinkDocStatusLabel(docStatus)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && !isLoading && (
                <TableRow style={{ height: 56 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRecords.length === 0 && !filterName && (
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Typography variant="subtitle2" noWrap>
                      You don't have any styled document yet, click "New Document" button to add one.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            {isRecordNotFound && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={filteredRecords.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
    
    {/* Delete dialog */}
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Delete ${selected.length} sync record(s)?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          When you delete, we will only remove the styled document here. We won't delete record on FreshBooks!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)}>Close</Button>
        <Button onClick={handleDeleteSelected} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </>
  );
}

/* eslint-disable no-bitwise */
import { useCallback, useEffect } from 'react';
import {
  collection,
  query as dbQuery,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  limit,
  db,
  setDoc,
  updateDoc,
  writeBatch,
  increment,
} from 'firebase/firestore';
import moment from 'moment';
import { linkDocStatus } from '../sections/@dashboard/invoiceTemp/invoiceUtil';
import useFirebase from './useFirebase';
import useSync from './useSync';

import { envVariable } from '../utils/constant';

const useLink = () => {
  const { firestoreDB } = useFirebase();
  const { getFBInvoiceSharableLink } = useSync();

  const hashSeed = 8808;

  const hashStr = (str, seed = hashSeed) => {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i += 1) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
  };

  //  linkid = hashStr(userID, invoiceID, tempID, appName)
  const generateLinkID = (userID, invoiceID, tempID, appName) =>
    hashStr(String(userID) + String(invoiceID) + String(tempID) + appName, Math.floor(Math.random() * hashSeed));

  // get / generate invoice sharable Link, link = https://onetracking.gelatech.com/link/ + LinkID
  const generateInvoiceSharableLink = useCallback(async (existLinkID, userID, invoiceObj, tempID, appName) => {
    let linkID = existLinkID;
    if (!linkID) linkID = generateLinkID(userID, invoiceObj.id, tempID, appName);
    console.log('linkID:', linkID);
    try {
      const docRef = doc(firestoreDB, 'link', linkID);
      const linkDoc = await getDoc(docRef);
      let fbSharableLink = null;
      if (!linkDoc.exists()) {
        console.log('this linkID not in db');
        // only call getFBInvoiceSharableLink when there is no link generated before
        // this mean we will change FB invoice status to sent if it's not
        fbSharableLink = await getFBInvoiceSharableLink(invoiceObj);
        addLinkDoc(userID, invoiceObj, tempID, appName, fbSharableLink, linkDocStatus.shared);
      } else if (!linkDoc.data().fb_sharable_link) {
        fbSharableLink = await getFBInvoiceSharableLink(invoiceObj);
        updateLinkDoc({ linkID, userID, invoiceObj, tempID, appName, fbSharableLink, status: linkDocStatus.shared });
      }
      console.log('fbSharableLink', linkDoc.data());

      return envVariable.onetracking_link_url_prefix + linkID;
    } catch (e) {
      console.error('Error get or add document to link collection: ', e);
      return null;
    }
  }, []);

  // save a new link doc to the DB
  const addLinkDoc = useCallback(async (userID, invoiceObj, tempID, appName, fbSharableLink, status) => {
    const linkID = generateLinkID(userID, invoiceObj.id, tempID, appName);
    console.log('linkID:', linkID);
    try {
      const newLinkDoc = {
        app_name: appName,
        template_id: tempID,
        invoice_id: invoiceObj.id,
        user_id: userID,
        additional_info: invoiceObj.additionalInfo || null,
        origin_doc: invoiceObj,
        fb_sharable_link: fbSharableLink || null,
        update_date: moment().unix(),
        create_date: moment().unix(),
        is_styled_doc: true,
        doc_status: status || linkDocStatus.draft,
      };

      setDoc(doc(firestoreDB, 'link', linkID), newLinkDoc);
      return linkID;
    } catch (e) {
      console.error('Error add document to link collection: ', e);
      return null;
    }
  });

  /**
   * update link doc data to the DB
   *
   * @param linkID the id of the link doc going to updated
   * @return the value
   */
  const updateLinkDoc = useCallback(async ({ linkID, userID, invoiceObj, tempID, appName, fbSharableLink, status }) => {
    const docRef = doc(firestoreDB, 'link', linkID);
    try {
      const updateLinkDoc = {
        ...(!appName ? null : { app_name: appName }),
        ...(!tempID ? null : { template_id: tempID }),
        ...(!invoiceObj ? null : { invoice_id: invoiceObj.id }),
        ...(!userID ? null : { user_id: userID }),
        ...(!invoiceObj ? null : { origin_doc: invoiceObj }),
        ...(!invoiceObj.additionalInfo ? null : { additional_info: invoiceObj.additionalInfo }),
        ...(!fbSharableLink ? null : { fb_sharable_link: fbSharableLink }),
        ...(!status ? null : { doc_status: status }),
        update_date: moment().unix(),
      };

      updateDoc(docRef, updateLinkDoc);
    } catch (e) {
      console.error('Error update document to link collection: ', e);
      return null;
    }
  });

  const getLinkDoc = useCallback(async (linkID) => {
    const docRef = doc(firestoreDB, 'link', linkID);
    try {
      return await getDoc(docRef);
    } catch (e) {
      console.error('Error fetch link document: ', e);
      return null;
    }
  }, []);


  const deleteLinkDocs = useCallback(async (linkIDs) => {
    try {
      const batch = writeBatch(firestoreDB);
      
      linkIDs.forEach((linkID) => {
        const docRef = doc(firestoreDB, 'link', linkID);
        batch.delete(docRef);
      });
  
      await batch.commit();
      console.log(`Successfully deleted ${linkIDs.length} documents`);
    } catch (e) {
      console.error('Error deleting documents from link collection: ', e);
      throw e;
    }
  }, [firestoreDB]);


  // get links obj from db
  const getLinkDocs = useCallback(async (userID) => {
    try {
      const q = dbQuery(
        collection(firestoreDB, 'link'),
        where('user_id', '==', userID),
        where('is_styled_doc', '==', true)
      );
      const querySnapshot = await getDocs(q);
      const links = [];
      querySnapshot.forEach((doc) => {
        links.push({ id: doc.id, ...doc.data() });
      });
      console.log('getLinks', links);
      return links;
    } catch (e) {
      console.error('Error fetch links document: ', e);
    }
  }, []);

  const getGeneratedLinkDoc = useCallback(async (userID, invoiceID, tempID, appName) => {
    const linkID = hashStr(String(userID) + String(invoiceID) + String(tempID) + appName);
    const linkDoc = await getLinkDoc(linkID);
    return linkDoc;
  }, []);

  return {
    generateInvoiceSharableLink,
    getLinkDoc,
    getLinkDocs,
    getGeneratedLinkDoc,
    addLinkDoc,
    updateLinkDoc,
    deleteLinkDocs,
  };
};

export default useLink;

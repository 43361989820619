/* Send Email Dialog */
import { useState } from 'react';
import { Autocomplete, Button, Chip, Dialog, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

SendViaEmailDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func,
  handleSendEmail: PropTypes.func,
  emailOptions: PropTypes.array,
  defaultEmail: PropTypes.string,
};

export default function SendViaEmailDialog({
  dialogOpen,
  handleDialogClose,
  handleSendEmail,
  handleSendTestEmail,
  emailOptions,
  defaultEmail,
}) {
  const [toEmails, setToEmails] = useState([]);
  const [emailNotes, setEmailNotes] = useState(null);

  console.log('SendViaEmailDialog', dialogOpen, emailOptions);

  const onDialogClose = (e) => {
    handleDialogClose();
  };

  const onClickEmailSent = async () => {
    handleSendEmail().then(() => {
      handleDialogClose();
    });
    // console.log('handleSendEmail', toEmails, invoiceSharableLink, emailNotes);
    // // call backend to send
    // sendInvoiceEmail(selectedInvoice, toEmails, invoiceSharableLink, emailNotes).then(() => {
    //   setEmailDialogOpen(false);
    //   showSnackMsg('Email sent success!');
    // });
  };

  return (
    <Dialog
      fullWidth
      open={dialogOpen}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack direction="column" alignItems="center" mt={4} mb={3} px={5} spacing={5}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h4">Send via Email</Typography>
          <Typography variant="subtitle">Please select the email or enter email address below.</Typography>
        </Stack>
        <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
          <Autocomplete
            multiple
            fullWidth
            id="tags-filled"
            options={emailOptions}
            defaultValue={[defaultEmail || null]}
            // freeSolo
            onChange={(e, v, r, d) => {
              console.log('email values', v);
              setToEmails(v);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Email Address" placeholder="Enter Email..." />
            )}
          />
          <TextField
            variant="outlined"
            label="Notes"
            fullWidth
            onChange={(e) => {
              setEmailNotes(e.target.value);
            }}
            placeholder="Say thank you or add speical notes..."
          />
        </Stack>
        <Stack direction="column" sx={{ width: '100%' }} alignItems="center" spacing={2}>
          <Stack direction="row" sx={{ width: '100%' }} justifyContent="center" spacing={2}>
            <Button sx={{}} onClick={handleSendTestEmail} variant="outlined" size="large">
              Send me test
            </Button>
            <Button sx={{}} onClick={onClickEmailSent} variant="contained" size="large">
              Send Email
            </Button>
          </Stack>
          <Button onClick={onDialogClose}>Close</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

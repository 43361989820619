import { useEffect, useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Typography, Avatar, Input, TextField, Button } from '@mui/material';
// components
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import useFullstory from '../../hooks/useFullstory';
import useTemplates from '../../hooks/useTemplates';

// ----------------------------------------------------------------------

export default function SupportPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { identity, user } = useAuth();
  const { sendSupportEmail } = useTemplates();

  const [contactName, setContactName] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [feedback, setFeedback] = useState();
  const [isSent, setIsSent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { FS } = useFullstory();

  const sendFeedback = () => {
    const fsSessionURL = FS.getCurrentSessionURL();
    const sessionID = fsSessionURL.substring(fsSessionURL.lastIndexOf('/') + 1);
    console.log(sessionID, user.data.current_app);
    setIsLoading(true);
    sendSupportEmail(contactName, contactEmail, feedback, sessionID, user.data.current_app).then(() => {
      setFeedback('');
      setIsSent(true);
      setIsLoading(false);
    });
  };

  const getEmail = () => identity?.email;
  const getName = () => identity?.first_name;

  const handleOpen = () => {
    setIsLoading(false);
    setOpen(true);
    setIsSent(false);
    setContactName(identity?.first_name);
    setContactEmail(identity?.email);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon="bi:question-circle-fill" color="primary" />
      </IconButton> */}

      <Button
        ref={anchorRef}
        onClick={handleOpen}
        component="label"
        role={undefined}
        variant="outlined"
        sx={{
          mr: 1,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
        startIcon={<Iconify icon="bi:question-circle-fill" color="primary" />}
      >
        Need Help?
      </Button>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {isSent ? (
          <Stack spacing={2} mx={1} my={1}>
            <Typography variant="subtitle2" noWrap>
              Feedback sent, you will receive a confirmation email from us soon!
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={2} mx={1} my={1}>
            <Stack spacing={0.75}>
              <Typography variant="subtitle2" noWrap>
                Need help, feel free to reach out
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                hellogelatech@gmail.com
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <TextField
                id="name"
                label="Your Name"
                defaultValue={getName()}
                value={contactName}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
              <TextField
                id="email"
                label="Your Email"
                defaultValue={getEmail()}
                value={contactEmail}
                onChange={(e) => {
                  setContactEmail(e.target.value);
                }}
              />
              <TextField
                id="outlined-multiline-flexible"
                label="Question or Feedback..."
                multiline
                maxRows={4}
                minRows={4}
                value={feedback}
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
              />
              <LoadingButton variant="contained" loading={isLoading} loadingPosition="center" onClick={sendFeedback}>
                Send
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </MenuPopover>
    </>
  );
}

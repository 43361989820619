import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

import { Stack, Switch, Typography } from '@mui/material';

PreviewSwitcher.propTypes = {
  isPreview: PropTypes.bool,
  OnSwitch: PropTypes.func,
};

export default function PreviewSwitcher({ isPreview, OnSwitch }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      sx={{ width: '100%', pb: 1 }}
    >
      <Typography>Sample Data</Typography>
      <Switch checked={isPreview} onChange={OnSwitch} />
      <Typography>Your Data</Typography>
    </Stack>
  );
}

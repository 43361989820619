import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TermsConditions() {
  return (
    <Page title="Privacy Policy (Squrarespace FreshBooks Syncing App)" sx={{ width: '100%' }}>
      <Container>
        <ContentStyle sx={{ textAlign: 'left', alignItems: 'left' }}>
          <Typography variant="h3" sx={{ marginBottom: 1 }}>
            Terms and Conditions
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Introduction
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            This agreement ("Terms") governs your use of the Squarespace to FreshBooks Integration ("Integration")
            developed by [Your Name/Company Name] ("We" or "Us"). The Integration synchronizes order data from your
            Squarespace store to your FreshBooks account, recording them as "Other Income."
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Acceptance of Terms
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            By installing or using the Integration, you agree to be bound by these Terms. If you do not agree to all the
            Terms, you may not use the Integration.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Integration Functionality
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The Integration automatically transfers order data, including product information and order totals, from
            your Squarespace store to your FreshBooks account. You are responsible for ensuring the accuracy of your
            Squarespace data.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Data Security
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            We take reasonable steps to protect your data. However, you acknowledge that no data transmission over the
            internet is guaranteed to be 100% secure. We are not responsible for any unauthorized access to your data.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Data Retention
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The Integration is provided "as is" and without warranty of any kind, express or implied. We disclaim all
            warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose,
            and non-infringement.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Disclaimer of Warranties
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The Integration is provided "as is" and without warranty of any kind, express or implied. We disclaim all
            warranties, including but not limited to, warranties of merchantability, fitness for a particular purpose,
            and non-infringement.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Limitation of Liability
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            We shall not be liable for any damages arising out of your use of the Integration, including but not limited
            to, direct, indirect, incidental, special, consequential, or punitive damages.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Term and Termination
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            These Terms shall remain in effect until terminated by either party. We may terminate these Terms at any
            time for any reason. You may terminate these Terms by discontinuing use of the Integration.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Intellectual Property
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The Integration is protected by intellectual property laws. You are granted a non-exclusive,
            non-transferable license to use the Integration in accordance with these Terms.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Governing Law
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            These Terms shall be governed by and construed in accordance with the laws of Ontario and the laws of
            Canada.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Entire Agreement
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            These Terms constitute the entire agreement between you and us regarding the Integration and supersede all
            prior or contemporaneous communications and proposals, whether oral or written.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Updates
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            We may update these Terms at any time. The updated Terms will be effective upon posting. By continuing to
            use the Integration after the updated Terms are posted, you agree to be bound by the updated Terms.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Contact Us
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            If you have any questions about these Terms, please contact us at hellogelatech@gmail.com.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink} sx={{ marginTop: 3 }}>
            Go back to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}

import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import TemplateCard from './TemplateCard';

// ----------------------------------------------------------------------

TemplateGrid.propTypes = {
  templates: PropTypes.array.isRequired,
  onTempClick: PropTypes.func,
};

export default function TemplateGrid({ templates, onTempClick, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {templates.map((temp) =>
        temp.isHidden ? null : (
          <Grid key={temp.id} item xs={12} sm={12} md={6} lg={4}>
            <TemplateCard
              template={temp}
              onClick={() => {
                console.log('temp click', temp.id);
                onTempClick(temp.id);
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  );
}

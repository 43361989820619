import { useNavigate, useParams } from 'react-router-dom';

// @mui
import Link from '@mui/material/Link';
import { alpha, styled } from '@mui/material/styles';
import {
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
  Stack,
  Divider,
  Avatar,
  Breadcrumbs,
  Snackbar,
  IconButton,
} from '@mui/material';
// components
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { APPBAR_DESKTOP } from '../layouts/dashboard/DashboardNavbar';
import Iconify from '../components/Iconify';
import CopyLinkDialog from '../layouts/dashboard/CopyLinkDialog';
import useAuth, { FBAppName } from '../hooks/useAuth';
import SendViaEmailDialog from '../layouts/dashboard/SendViaEmailDialog';
import SplitButton from '../components/SplitButton';
import SplitPayButton from '../components/SplitPayButton';
import {
  getCurrencyFormater,
  getLinkDocStatusLabel,
  linkDocStatus as docStatusMap,
} from '../sections/@dashboard/invoiceTemp/invoiceUtil';
import TemplatePreview from '../sections/@dashboard/invoice/TemplatePreview';
import Page from '../components/Page';
import useLink from '../hooks/useLink';
import useSync from '../hooks/useSync';
import useTemplates from '../hooks/useTemplates';
import templateComponentIndex from '../sections/@dashboard/invoiceTemp/TemplateComponentIndex';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function LinkDocDetailPage() {
  const navigate = useNavigate();
  const { linkID } = useParams();
  const { getLinkDoc, updateLinkDoc, generateInvoiceSharableLink } = useLink();
  const { fetchInvoiceByUserID } = useSync();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [linkDocStatus, setLinkDocStatus] = useState(null);
  const [fbLink, setFBLink] = useState(null);
  const [sharableLink, setSharableLink] = useState(null);
  const { downloadPDF, viewPlusOne, downloadPlusOne, sendPlusOne, getTemplateByID } = useTemplates();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const { getUser } = useAuth();
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const toolbarRef = useRef(null);

  // Snackbar feature
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const showSnackMsg = (msg) => {
    setSnackBarOpen(true);
    setSnackBarMsg(msg);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  console.log('linkID', linkID);

  const fetchLinkDoc = async () => {
    const linkDoc = await getLinkDoc(linkID);
    console.log('linkDoc', linkDoc.data());
    const docStatus = linkDoc.data()?.doc_status;
    const tempID = linkDoc.data()?.template_id;
    const tempObj = await getTemplateByID(tempID);
    const invoiceObj = linkDoc.data()?.origin_doc
      ? linkDoc.data()?.origin_doc
      : await fetchInvoiceByUserID(linkDoc.data().invoice_id, linkDoc.data().user_id);
    const fbSharableLink = linkDoc.data().fb_sharable_link;
    const addInfo = linkDoc.data().additional_info;
    invoiceObj.additionalInfo = { ...invoiceObj.additionalInfo, ...addInfo };
    console.log('invoiceObj', invoiceObj);
    return { docStatus, invoiceObj, tempObj, fbSharableLink };
  };

  const handleInvoiceChange = (updatedInvoice) => {
    setInvoice(updatedInvoice);
  };

  const renderInvoice = () => {
    const SelectedTemplate = templateComponentIndex[templateID];
    return SelectedTemplate ? (
      <SelectedTemplate 
        userInvoice={invoice} 
        isPreview 
        templateRef={templateRef} 
        editable={isEditing} 
        onInvoiceChange={handleInvoiceChange}
      />
    ) : null;
  };

  // generate/get Link, linkid = hash(userid,templateid,invoiceid)
  // if the status is not sent, Mark invoice as sent and get fb invoice sharable link
  const handleSendEvent = async (actionType) => {
    const userObj = await getUser();
    const sharableLink = await generateInvoiceSharableLink(
      linkID,
      userObj.id,
      invoice,
      templateID,
      FBAppName.invoice_in_style
    );
    console.log(sharableLink);
    setSharableLink(sharableLink);

    // Increase send number in db
    sendPlusOne(templateID);
    // save the activity action to db
    // addActivityToDB(actionType);
  };

  const handleSendEmail = (e) => {
    setIsEmailDialogOpen(true);
  };
  const handlSendEmailDialogClose = (e) => {
    setIsEmailDialogOpen(false);
  };

  const handlCopyShareLinkDialogClose = (e) => {
    setIsLinkDialogOpen(false);
  };

  const handleCopyShareLink = (e) => {
    handleSendEvent().then(() => {
      setIsLinkDialogOpen(true);
      setLinkDocStatus(docStatusMap.shared);
    });
  };

  const onCopyLink = () => {
    showSnackMsg('Link copied to your clipboard!');
  };

  const handlePrint = () => {
    window.print();
  };

  const templateRef = useRef(null);
  const handleDownload = () => {
    const fileName = `#${invoice.invoiceNumber} - ${templateID}`;
    // download invoice as pdf
    downloadPDF(fileName, templateRef);
    // incremente the download number for the selected invoice template
    downloadPlusOne(templateID);
  };

  const handleSaveChange = async () => {
    console.log('save changes');
    console.log(invoice);
    await updateLinkDoc({ linkID, invoiceObj: invoice });
    setIsEditing(false);
  };

  const handlePayment = () => {
    window.open(fbLink, '_blank');
  };

  // at moment only purchase order and packing slipe allow edit
  const isEditable = () => {
    if (!!templateID && (templateID === 'purchase_order_temp' || templateID === 'packing_slip_temp')) return true;
    return false;
  };

  const getDefaultEmailAddress = () => {
    console.log('invoice', invoice);
    switch (templateID) {
      case 'purchase_order_temp':
        return invoice?.additionalInfo?.shipperInfo?.contact_email;
      case 'packing_slip_temp':
        return invoice?.additionalInfo?.shiptoInfo?.contact_email;
      default:
        return invoice?.clientContacts[0].email;
    }
  };

  const refreshLinkDoc = () => {
    fetchLinkDoc().then((data) => {
      console.log('fetchLinkDoc', data);
      setIsLoading(false);
      setInvoice(data.invoiceObj);
      setTemplateID(data.tempObj.id);
      setTemplateName(data.tempObj.name);
      setLinkDocStatus(data.docStatus);
      setFBLink(data.fbSharableLink);
      // when open this link, increase view
      viewPlusOne(data.tempObj.id);
    });
  };

  useEffect(() => {
    refreshLinkDoc();

    const updateHeaderHeight = () => {
      if (toolbarRef.current) {
        setToolbarHeight(toolbarRef.current.offsetHeight);
      }
    };

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => window.removeEventListener('resize', updateHeaderHeight);
  }, []);

  return (
    <Page title="Styled Doc Detail Page">
      <Box
        ref={toolbarRef}
        sx={{
          position: 'fixed',
          top: APPBAR_DESKTOP,
          left: 0,
          right: 0,
          zIndex: 1000,
          borderBottom: '1px solid',
          borderColor: 'divider',
          boxShadow: 'none',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
          backgroundColor: alpha('#fff', 0.72),
        }}
      >
        <Container>
          {isEditing ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
              mx={2}
              mb={2}
              className="no-print"
            >
              <Stack direction="column" alignItems="left">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h4" gutterBottom>
                    {`Edit ${templateName}`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
                <Button variant="text" color="primary" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSaveChange}>
                  Save
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mx={2} className="no-print">
              <Stack direction="column" alignItems="left">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="primary"
                    href={'..'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    {`< Styled Doc List`}
                  </Link>
                </Breadcrumbs>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h4" gutterBottom>
                    {templateName || 'Loading...'}
                  </Typography>
                  {getLinkDocStatusLabel(linkDocStatus, 'medium')}
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
                {isEditable() && (
                  <Button variant="text" color="primary" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                )}
                <Button variant="outlined" color="primary" onClick={handleDownload}>
                  Download
                </Button>
                <Button variant="contained" color="primary" onClick={handleCopyShareLink}>
                  Share via Link
                </Button>
                {/* <SplitButton
                  options={['Share via Link', 'Share via Email']}
                  menuItemClickFuncs={[handleCopyShareLink, handleSendEmail]}
                /> */}
              </Stack>
            </Stack>
          )}
        </Container>
      </Box>
      <Container
        sx={{
          paddingTop: `${toolbarHeight}px`,
          '@media print': {
            '.no-print': { display: 'none !important' },
          },
        }}
      >
        <ContentStyle>
          {isLoading || !templateID || !invoice ? <CircularProgress sx={{ marginY: 'auto' }} /> : renderInvoice()}
        </ContentStyle>
        <SendViaEmailDialog
          dialogOpen={isEmailDialogOpen}
          handleDialogClose={handlSendEmailDialogClose}
          emailOptions={
            invoice && [invoice?.ownerContact.email, ...invoice?.clientContacts.map((contact) => contact.email)]
          }
          defaultEmail={getDefaultEmailAddress()}
        />
        <CopyLinkDialog
          sharableLink={sharableLink}
          dialogOpen={isLinkDialogOpen}
          handleCopyLink={onCopyLink}
          handleDialogClose={handlCopyShareLinkDialogClose}
        />
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          message={snackBarMsg}
          onClose={handleSnackBarClose}
          sx={{ bottom: { xs: 66 } }}
          action={
            <>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleSnackBarClose}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </>
          }
        />
      </Container>
    </Page>
  );
}

/* Send Email Dialog */
import { useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../components/Iconify';

CopyLinkDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func,
  handleCopyLink: PropTypes.func,
  sharableLink: PropTypes.string,
};

export default function CopyLinkDialog({ dialogOpen, handleDialogClose, handleCopyLink, sharableLink }) {
  console.log('CopyLinklDialog', sharableLink);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const onCopyLink = () => {
    navigator.clipboard.writeText(sharableLink);
    setIsLinkCopied(true);
    handleCopyLink();
    handleDialogClose();
  };

  return (
    <Dialog
      fullWidth
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack direction="column" alignItems="center" my={3} px={5} spacing={2}>
        <Typography variant="h4">Voilà - here's your link</Typography>
        <Typography variant="subtitle">You can copy, paste and share this link with your client.</Typography>
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="filled-adornment-link">Sharable Link</InputLabel>
          <FilledInput
            readOnly
            id="filled-adornment-link"
            value={isLinkCopied ? 'Copied!' : sharableLink}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={onCopyLink} edge="end">
                  <Iconify icon="clarity:copy-to-clipboard-line" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Stack direction="column" sx={{ width: '100%' }} alignItems="center" spacing={2}>
          <Button sx={{ px: 6, mt: 3 }} onClick={onCopyLink} variant="contained" size="large">
            Copy Link
          </Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
